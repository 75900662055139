button {
    font-family: Tajawal;
    margin: 4px;
    border-radius: 4px;
    border: 1px solid #cacaca;
    border-radius: 4px;
    font-size: 11pt;
    padding: 4px 10px;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
    background-color: #E6E6E6;
    color: #555;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E6E6E6), to(#CCCCCC));
    background-image: -webkit-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -moz-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -ms-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -o-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: linear-gradient(to bottom, #E6E6E6, #CCCCCC);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#E6E6E6, endColorstr=#CCCCCC);
    cursor: pointer;
}

button:hover {
    border: 1px solid #b3b3b3;
    background-color: #cdcdcd;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#cdcdcd), to(#b3b3b3));
    background-image: -webkit-linear-gradient(top, #cdcdcd, #b3b3b3);
    background-image: -moz-linear-gradient(top, #cdcdcd, #b3b3b3);
    background-image: -ms-linear-gradient(top, #cdcdcd, #b3b3b3);
    background-image: -o-linear-gradient(top, #cdcdcd, #b3b3b3);
    background-image: linear-gradient(to bottom, #cdcdcd, #b3b3b3);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#cdcdcd, endColorstr=#b3b3b3);
}

.search{
    padding-left:4px;
}

.keywords {
    width: 100% !important;
    line-height: 13px;
    background: none;
    border:1px solid #dcdcdc;
    height:26px !important;
    margin-bottom: 0px !important;
    font-size:15px;
    font-weight: normal;
    color: #767676;
    box-shadow:inset 0px 1px 0px 0px #ffffff;
    -moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
    box-shadow:inset 0px 1px 0px 0px #ffffff;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #f6f6f6));
    background:-moz-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-webkit-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-o-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-ms-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
    outline: 0 none;
}

.sb-menu .title {
    color: #595959;
    font-size: 14pt;
    font-family: Tajawal;
    line-height: 40px;
}

ul.sb-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #959595;
}


ul.sb-menu li {
    padding: 4px 0 4px 12px;
    width:100%;
}

ul.ex_menu li {
    padding: 4px 0 8px 12px;
}
ul.sb-menu li{
 padding-left:0 !important;
}
ul.sb-menu li {
    color: #444;
    text-decoration: none;
    margin-left: -12px;
    padding-left: 12px;
    cursor: pointer;
}


.sb-menu .info-text {
    cursor: default;
    padding:0 8px;
}
.sb-menu .info-text:last-child{
    padding-bottom: 16px;
}

ul.sb-menu li:not(#info-sub-menu):hover {

    text-decoration: none;
    //text-shadow: 8px 5px 5px grey;
}
.title.important{
    color: #BE1E2D !important;
}
.important {
    text-align: center;
    color:#256CA4;
}
.important a{
    color: #256CA4;
}

.important a:hover{
    color: #BE1E2D !important;
}

.material-icons {
    vertical-align: middle;
}

.sb-menu i {
    font-size: 18px;
    text-align: center;
    min-width: 30px;
    text-align: center;
    color:#00427A;
}

.sb-menu li {
    font-family: "Tajawal Light";
    font-weight: bold;

}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
ul{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.rb-menu-item span{
    width:calc(100% - 33px);
    display:inline-flex;
}
.sb-menu li,.rb-menu-item {

    -moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
    box-shadow:inset 0px 1px 0px 0px #ffffff;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #f6f6f6));
    background:-moz-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-webkit-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-o-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-ms-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0);
    background-color:#ffffff;
    border:1px solid #dcdcdc;
    display:inline-block;
    cursor:pointer;
    color:#666666;
    font-size:15px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0px 1px 0px #ffffff;
}
.dropdown-opener:hover i{
    color:white !important;
}

.sb-menu li:hover,.rb-menu-item:hover {


    background:-webkit-linear-gradient(top, #00427A 50%, #7892c2 100%);
    background:-o-linear-gradient(top, #00427A 5%, #7892c2 100%);
    background:-ms-linear-gradient(top, #00427A 5%, #7892c2 100%);
    background:linear-gradient(to bottom, #00427A 5%, #7892c2 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#7892c2',GradientType=0);
    color:white;
    text-shadow: none !important;
    background-color:#476e9e;
}
.sb-menu li:active, .rb-menu-item:active {
    position:relative;
    top:1px;
}


@-webkit-keyframes fa-beat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1.1);
    }
}

@keyframes fa-beat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1.1);
    }
}

.slide-sub-menu {
    display: block;
    visibility: visible; /* shows sub-menu */
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;

}
.info-sub-menu{
    z-index: 9;
    background:#e7e9eb;
    padding-right: 8px !important;
    width:380px !important;
    border-radius: 4px;
    font-size: 15px;
    box-shadow: 8px 5px 5px grey;
    border:1px solid grey;

}
.hide-sub-menu {
    display: none;
}

.menu-trigger{
    float:left;
}

.info-sub-menu i{
    font-size: 12px;
}
.active-menu-item {

    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #bbb), color-stop(1, #f6f6f6)) !important;
    border-left: 1px solid #444444 !important;
    border-right: 1px solid #444 !important;
}
.right-info-banner{
    width:100%;
    border-radius: 2px;
    border:1px solid #dcdcdc;
    margin:0 !important;
    -moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
    box-shadow:inset 0px 1px 0px 0px #ffffff;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #f6f6f6));
    background:-moz-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-webkit-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-o-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:-ms-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
    background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%) !important;
}
.right-info-banner .info-separator{
    background-color:#dcdcdc;
    height:1px;
    width:100%;
}