.dropdown{
    position: relative;
}
.dropdown li{
    padding-right: 8px !important;
    background-color: #E6E6E6 !important;
    height:32px;
}
.dropdown-opener i {
    color:#BE1E2D !important;

}
.dropdown .icon-arrow {
    color: #444;
}
.dropdown-menu i{
    margin-right: 0;
}
.dropdown .icon-arrow.open {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -moz-transition: -moz-transform 0.4s;
    -o-transition: -o-transform 0.4s;
    -webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
}
.dropdown .icon-arrow.close {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -moz-transition: -moz-transform 0.4s;
    -o-transition: -o-transform 0.4s;
    -webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
}
.dropdown .dropdown-menu {
    max-height: 0;
    overflow: hidden;
    list-style: none;
    padding:0;
    position: absolute;
    right: 100%;
    top: 5px;
    width: 260px;
}



.dropdown .show, .dropdown .hide {
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
}
.dropdown .show {
    display: block;
    max-height: 9999px;
    /*-moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    transform: scaleX(1);*/
    animation: showAnimation 0.5s ease-in-out;
    -moz-animation: showAnimation 0.5s ease-in-out;
    -webkit-animation: showAnimation 0.5s ease-in-out;
    -moz-transition: max-height 1s ease-in-out;
    -o-transition: max-height 1s ease-in-out;
    -webkit-transition: max-height 1s ease-in-out;
    transition: max-height 1s ease-in-out;
}
.dropdown .hide {
    max-height: 0;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    animation: hideAnimation 0.4s ease-out;
    -moz-animation: hideAnimation 0.4s ease-out;
    -webkit-animation: hideAnimation 0.4s ease-out;
    -moz-transition: max-height 0.6s ease-out;
    -o-transition: max-height 0.6s ease-out;
    -webkit-transition: max-height 2.6s ease-out;
    transition: max-height 0.4s ease-out;
}

@keyframes showAnimation {
    0% {
        -moz-transform: scaleX(0.1);
        -ms-transform: scaleX(0.1);
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    40% {
        -moz-transform: scaleX(1.04);
        -ms-transform: scaleX(1.04);
        -webkit-transform: scaleX(1.04);
        transform: scaleX(1.04);
    }
    60% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.04);
        -ms-transform: scaleX(1.04);
        -webkit-transform: scaleX(1.04);
        transform: scaleX(1.04);
    }
    100% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.02);
        -ms-transform: scaleX(1.02);
        -webkit-transform: scaleX(1.02);
        transform: scaleX(1.02);
    }
    100% {
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@-moz-keyframes showAnimation {
    0% {
        -moz-transform: scaleX(0.1);
        -ms-transform: scaleX(0.1);
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    40% {
        -moz-transform: scaleX(1.04);
        -ms-transform: scaleX(1.04);
        -webkit-transform: scaleX(1.04);
        transform: scaleX(1.04);
    }
    60% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.04);
        -ms-transform: scaleX(1.04);
        -webkit-transform: scaleX(1.04);
        transform: scaleX(1.04);
    }
    100% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.02);
        -ms-transform: scaleX(1.02);
        -webkit-transform: scaleX(1.02);
        transform: scaleX(1.02);
    }
    100% {
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@-webkit-keyframes showAnimation {
    0% {
        -moz-transform: scaleX(0.1);
        -ms-transform: scaleX(0.1);
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    40% {
        -moz-transform: scaleX(1.04);
        -ms-transform: scaleX(1.04);
        -webkit-transform: scaleX(1.04);
        transform: scaleX(1.04);
    }
    60% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.04);
        -ms-transform: scaleX(1.04);
        -webkit-transform: scaleX(1.04);
        transform: scaleX(1.04);
    }
    100% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.02);
        -ms-transform: scaleX(1.02);
        -webkit-transform: scaleX(1.02);
        transform: scaleX(1.02);
    }
    100% {
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@keyframes hideAnimation {
    0% {
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    60% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.02);
        -ms-transform: scaleX(1.02);
        -webkit-transform: scaleX(1.02);
        transform: scaleX(1.02);
    }
    100% {
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
}
@-moz-keyframes hideAnimation {
    0% {
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    60% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.02);
        -ms-transform: scaleX(1.02);
        -webkit-transform: scaleX(1.02);
        transform: scaleX(1.02);
    }
    100% {
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
}
@-webkit-keyframes hideAnimation {
    0% {
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    60% {
        -moz-transform: scaleX(0.98);
        -ms-transform: scaleX(0.98);
        -webkit-transform: scaleX(0.98);
        transform: scaleX(0.98);
    }
    80% {
        -moz-transform: scaleX(1.02);
        -ms-transform: scaleX(1.02);
        -webkit-transform: scaleX(1.02);
        transform: scaleX(1.02);
    }
    100% {
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
}