.content {
    direction: rtl;
    text-align: right;
    position: relative;
    color:#444;
    line-height: 25px;
}

.content b{
    color:#256CA4;
}
.paragraph-title {
    font-weight: bold;
    direction: rtl !important;
    padding-top:20px;
    color: #00427A;
}
.paragraph-title:after, .paragraph .title:after{
    content:''
}

.paragraph-details{

}
.page-title{
    font-family: Tajawal;
    text-align: right;
    font-weight: bold;
    font-size: 16pt;
    color:#00427A;
    padding: 10px 0 20px 0;
    text-align: center;
}

.background-1{
    background-image:url('images/1.jpeg') !important;
}
.background-2{
    background-image:url('images/2.jpeg')  !important;
}

.background-3{
    background-image:url('images/3.jpeg')  !important;
}
.background-4{
    background-image:url('images/4.jpeg')  !important;
}


@keyframes carousel {
    0% {
        background-image:url('images/1.jpeg') !important;
    }
    25% {
        background-image:url('images/2.jpeg')  !important;;
    }
    50% {
        background-image:url('images/3.jpeg')  !important;;
    }
    100% {
        background-image:url('images/4.jpeg')  !important;;
    }

}
#home-carousel{
    height:348px;
    width:680px;
    background-size: 672px 340px;
    border:1px solid #ccc;
    background-image:url('images/1.jpeg');
    background-repeat: no-repeat;
    background-position: 4px 4px;
    background-color:white;
    animation-name: carousel;
    animation-iteration-count: infinite;
    align-self: flex-start;
    margin: 0 auto 32px auto;
}
.center-panel{
    width:810px;
    padding: 0 24px 50px;
}

.paragraph{
    margin:8px 0;
}
.paragraph-details ul{
    list-style-type: circle;
}

.paragraph .title{
    font-weight: bold;
    direction: rtl !important;
    color: #00427A;
}
p {
    margin-block-start:8px;
    margin-block-end:18px;
}
.paragraph p{
    text-align: justify;
}
.paragraph .keyword{
    font-weight: bold;
}
.shortcuts li a:hover{
    color:#be1e2d;
}
.shortcuts a{
    color:#444;
}
.back-to-top{
    text-align: left;
}
.back-to-top i{
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}
.back-to-top a{
    color:#444;
}
.back-to-top a:hover{
    color:#be1e2d;
}

.cke_contents{
    height:900px !important;
}
.fa-dot-circle{
    font-size: 14px !important;
}

.page-introduction{
    padding:30px 0 0 10px;
}

.insurance-companies-list td p:nth-child(2){
    display: none;
}
.insurance-companies-list img{
    background-color: white;
}
.insurance-companies-list{
    margin:8px auto;
}
.insurance-companies-list a{
    color:#444;
    font-family: "Tajawal";
    display: block;
    margin:8px 0 !important;
    text-shadow: 2px 3px 8px grey;
}
.insurance-companies-list a:hover{
    color:#be1e2d;
    text-shadow: 2px 3px 8px white;
}
.insurance-companies-list img{
    border:1px solid #444;
    box-shadow: 2px 3px 8px grey;
}
.insurance-companies-list td{
    text-align: center;
}
.lazy-loader{
    visibility: hidden;
}
.red-hr{
    background-color: #00427A !important; /*#BE1E2D*/
    border:none;
    height:1px;
}
.vertical-separator{
    margin:32px 0;
}
.info-title{
    font-weight: 600;
    margin-left: 4px;
}
.info-title:after{
    content:':'
}
.questions strong{
    color:#00427A !important;
}
.facebook-link{
    text-align: center;
}
.info-line{
    display: block;
    width:100%
}