html,body {
  padding:0;
  margin:0;
  background:#e7e9eb url(resources/images/main_bg.gif) top repeat-x ;
  background-position-y: -230px;
  background-size: 100% 271px;
  height: 1vh;
}
.App {
  direction: rtl;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content{
  min-height:900px;
}
@font-face {
  font-family: Tajawal;
  src: url(resources/fonts/Tajawal-Black.ttf);

}
@font-face {
  font-family: 'Tajawal Light';
  src: url(resources/fonts/Tajawal-Light.ttf);
}
.page-footer table{
  width:100%;
}
.page-footer td{
  text-align: center;
}

.page-footer{
  background-color:#e7e9eb;
  padding:16px;
  margin-top:-3px;
}
.page-footer .important{
  font-weight: bold;
}

@media only screen and (max-width: 768px){
  .page-footer{
    width: max-content;
  }
}
.page-footer {
  color: #444;
  font-size: 11pt;
  line-height: 22px;
  font-family: Tajawal Light;
  font-weight: 600;
}
.page-footer .info-paragraph{
  padding-right: 8px;
}
.page-footer .info-paragraph span{

}
.page-footer .title{
  text-align: center;
  padding:8px;
  font-size: 14pt;
  font-weight: 600;
  text-shadow: 1px 7px 5px 0px white;
}
.page-footer .info{
  width:1080px;
  margin:auto;
}
.page-footer .info-label{
    font-weight: bold;
}
.page-footer .info-label:after{
  content: ' :';
  padding-left:4px;
}
.page-footer .info-column{
  display: inline-flex;
  width:50%;
  padding:8px 0;
  text-align: center;
}
.blue-label, .blue-label a{
  color:#3a99d5;
  text-decoration: none;
}
.main-area{
  width:1080px;
  margin-left: auto;
  margin-right: auto;
  background:white;
  border-bottom: 1px solid #999;
  box-shadow: 0px 7px 5px 0px rgba(68,68,68,1);
  margin-bottom:32px;

}
a{
  text-decoration: none;
}
a{
  color:#00427A;
}
a:hover{
  color:#BE1E2D;
}
