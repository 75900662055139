.menu_nav {
    padding:3px 0 0 10px;
    float:left;
    height:37px;
    background:white;
    border-radius: 4px;
    margin-top: -235px;
}
.menu_nav ul {
    list-style:none !important;
    padding:0;
    margin:0;

}
.menu_nav li {
    float:left;
    padding:0 5px;
    cursor: pointer;
}
.menu_nav li a {
    color:#4c4c4c;
    display:block;
    float:left;
    padding:6px 0;
    font-family: "Tajawal Medium";
    font-size: 13px;
    border-radius: 4px;
}
.menu_nav li a span {
    background:none;
    padding:5px 15px;
}

.menu_nav li a:hover {
    text-decoration: none;
    background:-webkit-linear-gradient(top, #00427A 50%, #7892c2 100%);
    background:-o-linear-gradient(top, #00427A 5%, #7892c2 100%);
    background:-ms-linear-gradient(top, #00427A 5%, #7892c2 100%);
    background:linear-gradient(to bottom, #00427A 5%, #7892c2 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#7892c2',GradientType=0);
    color:white;
    text-shadow: none;
    background-color:#476e9e;
}
.menu_nav li a:hover span {
    text-decoration:none;
    color:#f8f8f8;

}
.menu_nav li.active a {
    text-decoration:none;
    color:#f8f8f8;
    background:url(images/r_menu.gif) no-repeat right;
}
.menu_nav li.active a span {
    text-decoration:none;
    color:#f8f8f8;
    background:url(images/l_menu.gif) no-repeat left;
}
.menu-padding-left{
    width:31px;
    height:45px;
    background:#E7E9EB;
    border-top-left-radius: 18px;
    margin-left: 8px;

}
.menu-padding-right{
    width:31px;
    height:45px;
    background:#E7E9EB;
    border-top-right-radius: 18px;
    border:1px solid red;
    background: url(images/main_bg.gif) !important;
}
.menu-wrapper {
    display:inline-flex;
    float:right;
    height:45px;
    background: #41B5FF;
}
.menu_nav{

}
.right-corner{
    width:4px;
    height:45px;
    border-bottom-right-radius: 18px;
    background: url(images/main_bg.gif) !important;
}
.main-menu{
    position: absolute;
    right:10px;
    width:150px;
    top:30px;
}
.main-menu li{
    margin-bottom: 2px;
    display: inline-block;
    background:white;
    font-size:14px;
    width:100%;
    padding:8px;
    border-bottom-style: none;
    box-shadow: 3px 3px 5px grey;
}
.main-menu li:last-child{
    border-bottom-style: solid;
}
.main-menu i{
    margin-left: 4px;
}

.selected-menu-item{
    background:-webkit-linear-gradient(top, #00427A 50%, #7892c2 100%);
    background:-o-linear-gradient(top, #00427A 5%, #7892c2 100%);
    background:-ms-linear-gradient(top, #00427A 5%, #7892c2 100%);
    background:linear-gradient(to bottom, #00427A 5%, #7892c2 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#7892c2',GradientType=0);
    color:white !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.main-menu li:hover{
    text-decoration: none;
    background:-webkit-linear-gradient(top, #00427A 50%, #7892c2 100%);
    background:-o-linear-gradient(top, #00427A 5%, #7892c2 100%);
    background:-ms-linear-gradient(top, #00427A 5%, #7892c2 100%);
    background:linear-gradient(to bottom, #00427A 5%, #7892c2 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#7892c2',GradientType=0);
    color:white;
    text-shadow: none;
    background-color:#476e9e;
    box-shadow: none;
}