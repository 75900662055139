
button{
    font-family: Tajawal;
    margin:4px;
    border-radius: 4px;
    border: 1px solid #cacaca;
    border-radius: 4px;
    font-size: 11pt;
    padding: 4px 10px;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
    background-color: #E6E6E6;
    color:#555;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E6E6E6), to(#CCCCCC));
    background-image: -webkit-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -moz-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -ms-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -o-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: linear-gradient(to bottom, #E6E6E6, #CCCCCC);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#E6E6E6, endColorstr=#CCCCCC);
    cursor: pointer;
}


button:hover{
    border:1px solid #b3b3b3;
    background-color: #cdcdcd; background-image: -webkit-gradient(linear, left top, left bottom, from(#cdcdcd), to(#b3b3b3));
    background-image: -webkit-linear-gradient(top, #cdcdcd, #b3b3b3);
    background-image: -moz-linear-gradient(top, #cdcdcd, #b3b3b3);
    background-image: -ms-linear-gradient(top, #cdcdcd, #b3b3b3);
    background-image: -o-linear-gradient(top, #cdcdcd, #b3b3b3);
    background-image: linear-gradient(to bottom, #cdcdcd, #b3b3b3);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#cdcdcd, endColorstr=#b3b3b3);
}
input[type=text]{
    margin:2px 0;
    padding:5px 2px;
    height:16px;
    font-family: Tajawal;
}
label{
    display:block;
    margin:0;
    padding:16px 0 0 0;
    color:#555;
    font-size: 11pt;
}
form table td{
    text-align: right;
}
.search input:placeholder-shown{
    background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAaDgAAGg4BnPO/fAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANsSURBVEiJvZZNaFxVFMf///umC0HoTOxGChUMBUGM2CF2kUwN70uFmtn0gaiIolgX7nWVTheiCO4KSncpdlFildKo6fsYSJWIH0OKWMwquPFjk3kEEZGm97jIe8OdOJP3MiE9q3f+75zz+987lzuPGBKtVkutrKxMi0hTRJ4C8CCAIwA2APwhIl8DuNZoNJZbrZYeNme34CDRcRyP5PsATpSY8ZPW+p12u/3VvuBBEFhpmr4L4O29DgJwMU3Ttzqdzp09w4MgsLrd7uckn/tfEXlba72qlOpqrcdITgCYGDAvTNP0dFkDlfyh2+1+uAMsJK+QPB+G4drORs/zjovIOQAvGIvwa7XaBQBny8AJ9H7j0ND/FZHXkiS5XDTA87xAROYB3JdrSqnZMAyvF/UqACT5nqEJydfLgAEgiqIFAC8BkFzTWn8wMzNTGd6VwX3fbwCo5wLJK1EUfVIGnEccx5+RvGRIj1QqFacQLiJNUyB5fi/gPCzLasFYPYDmkNI++Ckj/3nQ4SoTS0tLvwL4Mc9JnhpencEBHM0TEbk1CtiIXr+IHN2tMIc/0EuU6u4TvmE8V+v1+qEieK9Baz22H7KIHDHSv4ouGwXg9zzJbq6Rg+RjRvpbUb0SkW+MfMLzvOOjgF3XPQZg0pBuFsIBXDMFrfXcKHCSc9m8PP+iEN5oNJYBrBpNLzqO8/xewK7rNkXkVUNam5qaWiw0DAC2bT+rlPrS0P8RkZeTJPm0aIDnebMichnA/YZ8Jo7jq6XgmfuPALxpvBOSlyzLamUXSF+4rnuM5Fy2YnO756MoeqUI3Aev1+uHarXaIgB/R40A6IjILZIbAMaw/V8+aUJ7xSI3SX4rIleTJPmhFNwwcAHAG2Wc9w0i50XkIQAzmbSplHo6DMPvhvX0Oe90OnfiOD6rlJolebskdw3AmWyrlw39sNb6hu/7J0vB8wjD8Hq1Wn0cwDMAPs6MbGav/wbwC4CLJJvT09OP5oeL5KJR1zNg2/aTgzgDv16HRRAE1sLCwt3danzfP6m1vgHgsCFvaq39drv9/cjwsmHb9gmlVITtw9kzsPMMHAi8rIEDg5cxcKDw3Qxorf0DhwOA4ziT2ad51ZDjewIHANd1nwAQY3sHVi3Lcq17BV9fX/9zfHy8DeDhra2t00mSpP8BkhxOr0M0PR4AAAAASUVORK5CYII=');
    background-position: right 5px center;
    background-repeat: no-repeat;
    background-size: 22px auto;
}